.sidebar .nav {
	li > a {
		display: flex !important;
		justify-content: flex-start !important;
		align-items: center !important;
		gap: 1em !important;

		font-weight: 600;

		span {
			display: flex !important;
			justify-content: space-between !important;
			align-items: center !important;
			width: 100% !important;
		}
	}

	.caret {
		position: inherit !important;
	}

	.sidebar-mini-icon {
		padding-left: 2em;
		width: auto !important;
		font-weight: 400;
	}

	.sidebar-normal {
		font-weight: 400;
	}
}
