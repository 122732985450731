.report-page {
	.report-table {
		.record-run {
			.fa-spin {
				position: static;
				margin: 0px;
			}
		}
	}
}
