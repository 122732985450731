#top-nav {
	height: 90px;
	width: 100%;
	z-index: 1030;
}

@media (min-width: 992px) {
	#top-nav {
		height: auto;
	}

	.navbar-expand-lg {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
}

.p-0 {
	padding: 0 !important;
}
.flex-wrap {
	flex-wrap: wrap !important;
}
.navbar,
.navbar > .container,
.navbar > .container-fluid {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}
.navbar {
	position: relative;
	padding: 0.5rem 1rem;
}

.bg-blue {
	background-color: #003f87 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

#top-nav .upper {
	background: #036;
	height: 40px;
	width: 100%;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;

	.text-logo h5 {
		font-size: 16px;
	}

	.text-logo h6 {
		font-size: 12px;
	}

	.text-logo h5,
	.text-logo h6 {
		font-weight: 400;
		color: #fff;
		font-family: trajan-pro-3, serif;
		line-height: 1.2em;
		margin-bottom: 0.5rem;
	}

	.navbar-brand {
		width: 55px;
		height: 60px;
		position: relative;
		height: 100%;
		top: -2px;
		left: -3px;
		z-index: 1035;

		img {
			width: 55px;
			height: auto;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.bsa-topbar {
	.fixed-top {
		position: fixed;
		right: 0;
		left: 0;
		z-index: 1030;
		top: 0;
	}
}

@media (min-width: 992px) {
	#top-nav .upper {
		height: 55px;

		.navbar-brand {
			margin-right: 15px;
			width: 75px;
			height: 100%;
			top: -4px;
			left: 0;

			img {
				width: 75px;
				height: 82px;
			}
		}
	}
}
