.member-upload-overlay {
	padding: 10px;

	.nav {
		text-align: center;
		display: flex;
		width: 100%;
		border-bottom: 1px solid #ddd;
		margin-bottom: 10px;

		.nav-item {
			flex: 1 1 0;
			text-align: center;
		}

		.nav-item .nav-link {
			border: 0px;
			font-weight: 400;

			&.selected {
				font-weight: 700;
			}
		}

		.nav-item:first-child .nav-link,
		.nav-item:last-child .nav-link {
			border-radius: 0px !important;
		}
	}

	.download-template {
		border-top: 1px solid #ddd;
		margin-top: 10px;
		button {
			font-weight: 400;
		}
	}
}
