.full-page-loading {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1050;

	.fa-spinner {
		font-size: 144px;
		color: #999;
	}
}
