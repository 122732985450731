.alert {
	border: 0;
	border-radius: $border-radius-small;
	color: $white-color;
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	position: relative;

	&.alert-success {
		background-color: lighten($success-color, 5%);
	}

	&.alert-danger {
		background-color: lighten($danger-color, 5%);
	}

	&.alert-warning {
		background-color: lighten($warning-color, 5%);
	}

	&.alert-info {
		background-color: lighten($info-color, 5%);
	}

	&.alert-primary {
		background-color: lighten($primary-color, 5%);
	}

	.close {
		color: $white-color;
		opacity: 0.9;
		text-shadow: none;
		line-height: 0;
		outline: 0;

		i.fa {
			font-size: 14px !important;
		}

		&:hover,
		&:focus {
			opacity: 1;
		}
	}

	span[data-notify='icon'] {
		font-size: 27px;
		display: block;
		left: 19px;
		position: absolute;
		top: 50%;
		margin-top: -11px;
	}

	button.close {
		position: absolute;
		right: 10px;
		top: 50%;
		margin-top: -13px;
		width: 25px;
		height: 25px;
		padding: 3px;
	}

	.close ~ span {
		display: block;
		max-width: 89%;
	}

	&.alert-with-icon {
		padding-left: 65px;
	}
}
