.btn,
.navbar .navbar-nav > a.btn {
	i {
		margin-right: 3px;
	}
}
.table .btn-group + .btn-group {
	margin-left: 4px;
}
.btn + .btn,
.btn-group + .btn-group {
	margin-left: 5px;
}
