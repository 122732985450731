.dropzone {
	min-height: 250px;

	padding: 30px;
	outline: none;
	cursor: pointer;

	position: relative;

	.dropzone-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	.fa-cloud-upload {
		font-size: 144px;
		color: #ccc;
	}
}
