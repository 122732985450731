.notification-settings-record-overlay {
	.variables-button button {
		margin: 0px;
	}
}

.ck-editor__editable_inline {
	min-height: 200px;
}

.notification-settings-record-overlay-popover {
	max-width: 400px;
}
