.login-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		z-index: 1075;
	}

	.backdrop {
		opacity: 0.5;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1070;
		width: 100vw;
		height: 100vh;
		background-color: #000;
	}
}
