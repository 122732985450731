.file-processing {
	min-height: 250px;

	padding: 30px;
	outline: none;

	position: relative;

	.processing-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	.fa-spinner {
		font-size: 96px;
		color: #999;
	}
}
