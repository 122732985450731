// Includ the sub-page SASS
@import './members-display/member-details-overlay/member-details-overlay';
@import './members-import/import-helpers/_member-upload-overlay';
@import './members-import/import-helpers/_import';

.approval-notification-alert {
	padding-left: 65px;
	cursor: pointer;

	span {
		top: inherit !important;
	}
}

.members-table {
	// This will make all of the non-sortable columns black text
	th {
		color: #212529 !important;
	}

	.filter-column {
		color: #51cbce !important;
		cursor: pointer;
		position: relative;
		line-height: 21px;
		padding: 12px 7px;

		.sort-icon {
			visibility: hidden;
			// display: none;
			right: left;
			position: relative;

			&.show {
				visibility: visible;
			}
		}

		&:hover {
			.sort-icon {
				visibility: visible;
			}
		}

		.filter-content {
			left: -1px;
			top: (12px+21px+12px+0px);
			display: none;
			position: absolute;
			background-color: #fff;
			padding: 10px;
			border: 1px solid #dee2e6;
			color: #000;
			font-size: 14px;
			font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
			font-weight: 400;
		}

		&.show-filter {
			border: 1px solid #dee2e6;
			// border-bottom: 2px solid #fff;

			.filter-content {
				display: block;
			}
		}
	}

	.table-pagniation {
		text-align: center;

		nav {
			display: inline-block;
		}
	}
}
