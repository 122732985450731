.admin-top-nav-bar {
	.navbar-brand {
		sup {
			color: #cf7cc4;
			font-size: 12px;
		}
	}

	.justify-content-end {
		.avatar {
			color: #fff;
			background-color: #adb5bd;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			height: 48px;
			width: 48px;

			img {
				border-radius: 0.375rem;
			}

			i {
				margin-bottom: '5px';
				font-size: '25px';
			}
		}

		.rounded-circle img {
			border-radius: 50% !important;
		}

		.avatar-sm {
			width: 36px;
			height: 36px;
			font-size: 0.875rem;
		}

		.text-sm {
			text-transform: uppercase;
		}
	}

	.navbar-nav .dropdown-menu {
		.dropdown-item {
			&.active,
			&:active {
				background-color: #eee !important;
			}
		}
	}
}
