.top-nav-bar-search {
	.input-group {
		.input-group-text {
			background-color: rgba(222, 222, 222, 0.3);
			border: 0px;
			margin-left: 0px !important;

			color: #66615b;
		}
	}
	.suggestions {
		display: none;
		background-color: #eceae7;
		border: 0px;
		border-left: 1px solid #999;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		border-top-width: 0;
		list-style: none;
		margin-top: 0;
		margin-bottom: 0px;
		max-height: 143px;
		overflow-y: auto;
		padding-left: 0;
		width: calc(150px + 40px);
		position: absolute;

		&.show {
			display: block;
		}

		li {
			padding: 0.5rem;

			display: flex;

			span {
				width: 100%;
				&.archived {
					text-align: right;
					i {
						color: #999;
					}
				}
			}
		}

		.suggestion-active,
		li:hover {
			background-color: #e6e5e3;
			// color: #fae042;
			cursor: pointer;
			// font-weight: 700;
		}
		li:not(:last-of-type) {
			border-bottom: 1px solid #999;
		}

		.no-suggestions {
			color: #999;
			padding: 0.5rem;
		}
	}
}
