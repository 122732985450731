.quick-approval {
	iframe {
		width: 100%;
		height: 90%;
	}

	.information-data {
		.data-value {
			display: flex;
			align-items: center;
		}
	}
}
