@import './paper-dashboard/variables';
@import './paper-dashboard/mixins';

// Plugins CSS
@import './paper-dashboard/plugins/plugin-bootstrap-switch';
@import './paper-dashboard/plugins/plugin-bootstrap-table';
@import './paper-dashboard/plugins/plugin-animate-bootstrap-notify';
@import './paper-dashboard/plugins/plugin-perfect-scrollbar';
@import './paper-dashboard/plugins/plugin-sweetalert2';
@import './paper-dashboard/plugins/plugin-card-wizard';
@import './paper-dashboard/plugins/plugin-datetimepicker';
@import './paper-dashboard/plugins/plugin-bootstrap-select';
@import './paper-dashboard/plugins/plugin-jasny-fileupload';
@import './paper-dashboard/plugins/plugin-tagsinput';
@import './paper-dashboard/plugins/plugin-datatables.net';
@import './paper-dashboard/plugins/plugin-jquery.jvectormap';
@import './paper-dashboard/plugins/plugin-fullcalendar';

// Core CSS
@import './paper-dashboard/buttons';
@import './paper-dashboard/social-buttons';
@import './paper-dashboard/animated-buttons';
@import './paper-dashboard/inputs';
@import './paper-dashboard/typography';
@import './paper-dashboard/misc';
@import './paper-dashboard/misc-extend';
@import './paper-dashboard/navbar';
@import './paper-dashboard/dropdown';
@import './paper-dashboard/alerts';
@import './paper-dashboard/images';
@import './paper-dashboard/tables';
@import './paper-dashboard/footers';
@import './paper-dashboard/fixed-plugin';

// components
@import './paper-dashboard/checkboxes-radio';
@import './paper-dashboard/progress';
@import './paper-dashboard/badges';
@import './paper-dashboard/pagination';
@import './paper-dashboard/info-areas';
@import './paper-dashboard/pills';
@import './paper-dashboard/tabs';
@import './paper-dashboard/popups';
@import './paper-dashboard/modals';
@import './paper-dashboard/carousel';
@import './paper-dashboard/sidebar-and-main-panel';
@import './paper-dashboard/timeline';

// cards
@import './paper-dashboard/cards';
@import './paper-dashboard/cards/card-map';
@import './paper-dashboard/cards/card-user';

@import './paper-dashboard/cards/card-background';
@import './paper-dashboard/cards/card-collapse';
@import './paper-dashboard/cards/card-contributions';
@import './paper-dashboard/cards/card-info-area';
@import './paper-dashboard/cards/card-lock';
@import './paper-dashboard/cards/card-pricing';
@import './paper-dashboard/cards/card-profile';
@import './paper-dashboard/cards/card-signup';
@import './paper-dashboard/cards/card-stats-mini';
@import './paper-dashboard/cards/card-stats';
@import './paper-dashboard/cards/card-subcategories';
@import './paper-dashboard/cards/card-testimonials';
@import './paper-dashboard/cards/card-tasks';

// example pages and sections
@import './paper-dashboard/example-pages';
@import './paper-dashboard/sections';

@import './paper-dashboard/responsive';
@import './paper-dashboard/media-queries';

// react differences
@import './paper-dashboard/react/react-differences';

// CK Editor
:root {
	--ck-z-default: 100;
	--ck-z-modal: calc(var(--ck-z-default) + 999);
}

.ck > .ck-balloon-panel {
	z-index: 1060 !important;
}

// Customized components
@import '../../components/Auth/login-overlay';
@import '../../components/Dropzone/dropzone';
@import '../../components/Settings/partials/notification-settings/notification-settings';
@import '../../components/Members/Members';
@import '../../components/Frontend/topbar';
@import '../../components/Frontend/check-clearances';
@import '../../components/FullPageLoading/full-page-loading';
@import '../../components/QuickApproval/quick-approval';

body {
	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.table {
		thead th {
			vertical-align: bottom;
			border-bottom: 1px solid #dee2e6;
		}

		tbody tr:last-child td {
			border-bottom: 0px;
		}
	}

	.form-select {
		line-height: normal;
		font-size: 14px;
		color: #66615b;
	}

	.modal-header {
		display: flex !important;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 1rem;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
	}
}
